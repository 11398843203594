"use client";

import { Alert } from "antd";

export default function NotFound() {
  return (
    <>
      <Alert
        type="error"
        message={
          <div className="items-center">
            <p>
              Sorry, we can`t find the page you`re looking for. If you believe this is a mistake, please contact us at
              <a href="mailto:support@condoit.io">support@condoit.io</a>
            </p>
          </div>
        }
      />
    </>
  );
}
